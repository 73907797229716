<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { getAction } from '@/command/netTool'
export default {
  name: 'aBulk',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/peopleTeam/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '有效期',
          type: 'rangePicker',
          keys: ['startDate', 'endDate']
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '团购名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length
        },
        {
          dataIndex: 'startDate',
          title: '有效期',
          align: 'left',
          customRender: (text, records) => {
            return `${text} ~ ${records.endDate}`
          },
          onExport: (text, records) => {
            return `${text} ~ ${records.endDate}`
          }
        },
        {
          dataIndex: 'minPrice',
          title: '拼团价',
          align: 'left',
          customRender: (text, records) => {
            return text !== records.maxPrice ? `${text} ~ ${records.maxPrice}` : text
          },
          onExport: (text, records) => {
            return text !== records.maxPrice ? `${text} ~ ${records.maxPrice}` : text
          }
        },
        {
          dataIndex: 'minOwnerPrice',
          title: '团长价',
          align: 'left',
          customRender: (text, records) => {
            return text !== records.maxOwnerPrice ? `${text} ~ ${records.maxOwnerPrice}` : text
          },
          onExport: (text, records) => {
            return text !== records.maxOwnerPrice ? `${text} ~ ${records.maxOwnerPrice}` : text
          }
        },
        {
          dataIndex: 'succeedCount',
          title: '拼团成功',
          align: 'left'
        },
        {
          dataIndex: 'loadCount',
          title: '拼团中',
          align: 'left'
        },
        {
          dataIndex: 'teamCount',
          title: '开团数量',
          align: 'left'
        },
        {
          dataIndex: 'upStatus',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red'
            }
          }
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          width: '8%',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: '团购详情',
                onClick: () => {
                  this.$router.push('/marketingCenter/bulkDetail?id=' + records.id)
                }
              },
              {
                display: true,
                name: records.upStatus == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.edit.call(this, {
                    url: `/peopleTeam/${records.upStatus == 1 ? 'up' : 'down'}/${records.id}`
                  })
                }
              },
              {
                display: true,
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/aBulkDetail?id=' + records.id)
              },
              {
                display: true,
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/peopleTeam/delete/${records.id}`
                  })
                }
              }
            ].filter(e => e.display)
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.$router.push('/marketingCenter/aBulkDetail')
          }
        }
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: data => {
        //         let idList = data.map(e => e.id)
        //         return api.command.delPut.call(this, {
        //           url: '/turntable/deleteBatch',
        //           params: { idList: idList }
        //         })
        //       }
        //     },
        //     {
        //       name: '批量上架',
        //       onClick: data => {
        //         let idList = data.map(e => e.id)
        //         return api.command.edit.call(this, {
        //           url: '/turntable/upBatch',
        //           params: { idList: idList }
        //         })
        //       }
        //     },
        //     {
        //       name: '批量下架',
        //       onClick: data => {
        //         let idList = data.map(e => e.id)
        //         return api.command.edit.call(this, {
        //           url: '/turntable/downBatch',
        //           params: { idList: idList }
        //         })
        //       }
        //     }
        //   ]
        // }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
